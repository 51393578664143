import "./App.css";
import { Routes, Route } from "react-router-dom";
import { createContext, useReducer } from "react";
import axios from "axios";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Contact from "./components/Contact";
import Signup from "./components/auth/Signup";
import Logout from "./components/auth/Logout";
import Login from "./components/auth/Login";
import ErrorPage from "./components/ErrorPage";
import Halls from "./components/halls/Halls";
import BookingForm from "./components/bookings/BookingForm";
import BookingsAdmin from "./components/bookings/BookingsAdmin";
import AdminDashboard from "./components/dashboard/AdminDashboard";
import FacultyDashboard from "./components/dashboard/FacultyDashboard";
import BookingFaculty from "./components/bookings/BookingsFaculty";
import Footer from "./components/Footer";
import HallsAdmin from "./components/halls/HallsAdmin";
import { initialState, reducer } from "./reducer/UseReducer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HallsEdit from "./components/halls/HallsEdit";
import HallForm from "./components/halls/HallForm";
import PasswordReset from "./components/auth/PasswordReset";
import ForgotPassword from "./components/auth/ForgotPassword";
import VerifySuccess from "./components/auth/VerifySuccess";
import Unauthorized from "./components/Unauthorized";
import BookingUpdateFrom from "./components/bookings/BookingUpdateForm";
import Events from "./components/bookings/Events";
import BookingsView from "./components/bookings/BookingView";
import { CalendarView } from "./components/CalendarView";
import BookingsDean from "./components/bookings/BookingsDean";
import DeanDashboard from "./components/dashboard/DeanDashboard";
import UserInfo from "./components/Stats";



export const UserContext = createContext();

const App = () => {
  const token = localStorage.getItem("jwtoken");
  if (token) {
    axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
    axios.defaults.withCredentials = true;
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <UserContext.Provider value={{ state, dispatch }}>
        <Navbar />
        <Routes>
          <Route path="/" element={
            state.userType === "admin" ? <AdminDashboard /> :
            state.userType === "faculty" ? <FacultyDashboard /> :
            process.env.REACT_APP_DEAN_FEATURE && state.userType === "dean" ? <DeanDashboard /> :
            <Login />
          } />
          <Route path="/Stats" element={ state.userType === "admin" ? <UserInfo />: <Login />} />

          <Route path="/profile" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/calendar" element={<CalendarView />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/passwordReset" element={<PasswordReset />} />
          <Route path="/forgotPassword/:id/:token" element={<ForgotPassword />} />
          <Route path="/events" element={<Events />} />
          <Route path="/verifyEmail/:id/:token" element={<VerifySuccess />} />
          <Route path="/halls" element={state.userType === "admin" ? <HallsAdmin /> : <Halls />} />
          <Route path="/halls/:hallId/:hallName" element={state.userType === "admin" ? <HallsEdit /> : <Unauthorized />} />
          <Route path="/bookingsEdit/:bookingId" element={
            state.userType === "admin" ? <BookingUpdateFrom /> :
            process.env.REACT_APP_DEAN_FEATURE && state.userType === "dean" ? <BookingUpdateFrom /> :
            <Unauthorized />
          } />
          <Route path="/hallForm" element={state.userType === "admin" ? <HallForm /> : <Unauthorized />} />
          <Route path="/bookings" element={
            state.userType === "admin" ? <BookingsAdmin /> :
            state.userType === "faculty" ? <BookingFaculty /> :
            process.env.REACT_APP_DEAN_FEATURE && state.userType === "dean" ? <BookingsDean /> :
            <Unauthorized />
          } />
          <Route path="/bookingForm/:hallId/:hallName" element={<BookingForm />} />
          <Route path="/bookingsView/:bookingId" element={<BookingsView />} />
          <Route path="/*" element={<ErrorPage />} />
        </Routes>
        <Footer />
      </UserContext.Provider>

      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default App;
